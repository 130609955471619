import classNames from 'classnames';
import React, { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { CUSTOM_BANNERS_TYPE_SALE_TAG } from '@constants/actions/customBanners';

import ErrorBoundary from '@components/common/ErrorBoundary';

import { calculateDiscountPercent } from '@components/pages/productsList/utils';
import { useTranslation } from '@hooks/useTranslation';

import { IProductInList } from '@interfaces/product';
import { IStore } from '@interfaces/store';
import { ICustomBanners, ISiteSettings } from '@interfaces/pageData/reducer';

import itemStyles from '../styles';

interface IProductImageTopActionsProps {
  product: IProductInList;
  currentProductId: number;
  rightAction?: ReactNode;
}

const ProductImageTopActions: React.FunctionComponent<IProductImageTopActionsProps> = ({
  product,
  currentProductId,
  rightAction,
}) => {
  const classesItem = itemStyles();
  const outletTag = useSelector<IStore, ISiteSettings['enable30dayPrice'] | undefined>((state: IStore) => state.pageData.data.siteSettings?.outletTag);
  const customBanners = useSelector<IStore, ICustomBanners | undefined>((state: IStore) => state.pageData.data.customBanners);
  const { t } = useTranslation();

  const isFresh = product.fresh;
  const currentColor = product.colors.find((colorItem) => String(colorItem.productId) === String(currentProductId)) || product.colors[0];
  const isOutletProduct = currentColor?.outlet || false;
  const discountPrice = Number(currentColor?.price.priceDiscount);
  const withDiscountPrice = discountPrice > 0;
  const discountPercent = withDiscountPrice ? calculateDiscountPercent(currentColor?.price) : null;
  const hasDiscountPrice = withDiscountPrice && discountPercent;
  const discountText = isOutletProduct && outletTag ? 'Outlet' : `-${discountPercent}%`;
  const saleTag = useMemo(() => customBanners?.other?.find((item) => item.type === CUSTOM_BANNERS_TYPE_SALE_TAG), [customBanners?.product_list]);
  return (
    <ErrorBoundary>
      {(rightAction || isFresh || hasDiscountPrice || isOutletProduct || saleTag) && (
        <div className={classesItem.productFullTopAction}>
          {hasDiscountPrice && (
            <div
              className={classNames(classesItem.tag, classesItem.discountTag, classesItem.tagBlur)}>{discountText}</div>
          )}
          {!hasDiscountPrice && saleTag && (
            <div
              className={classNames(classesItem.tag, classesItem.saleTag, classesItem.tagBlur)}>{saleTag.text}</div>
          )}
          {isFresh && <div
            className={classNames(classesItem.tag, classesItem.freshTag, classesItem.tagBlur)}>{t('product.freshTag')}</div>}
          {rightAction && rightAction}
        </div>
      )}
    </ErrorBoundary>
  );
};

export default ProductImageTopActions;
